import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostBottomImages = ({ imageone, imagetwo }) => {
  const imageOneDisplay = getImage(
    imageone.localFile.childImageSharp.gatsbyImageData
  )
  const imageOneAlt = imageone.altText

  const imageTwoDisplay = getImage(
    imagetwo.localFile.childImageSharp.gatsbyImageData
  )
  const imageTwoAlt = imagetwo.altText
  return (
    <DivStyled>
      <div className="image-wrapper">
        <div className="img-wrap img-one">
          <GatsbyImage
            image={imageOneDisplay}
            alt={imageOneAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="img-wrap img-two">
          <GatsbyImage
            image={imageTwoDisplay}
            alt={imageTwoAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </DivStyled>
  )
}

const DivStyled = styled.div`
  position: relative;

  .image-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 50rem;

    @media (min-width: 768px) {
      min-height: 50rem;
    }

    @media (min-width: 1025px) {
      min-height: 50rem;
    }
  }

  .img-wrap {
    position: absolute;
    top: 0;
    bottom: 0;

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .img-one {
    right: 0;
    left: 0;

    @media (min-width: 768px) {
      right: 26%;
      left: 0;
    }
  }

  .img-two {
    display: none;

    @media (min-width: 768px) {
      display: block;
      right: 0;
      left: 75%;
    }
  }
`

export default PostBottomImages

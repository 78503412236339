import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Blue, colors, H1White, medWrapper } from "../../../styles/helpers"

const PostHeader = ({ image, date, author, title }) => {
  const iconDisplay = getImage(image.localFile.childImageSharp.gatsbyImageData)
  const iconAlt = image.altText
  const options = { year: "numeric", month: "long", day: "numeric" }
  const postDate = new Date(date).toLocaleDateString(undefined, options)
  return (
    <HeaderStyled>
      <div className="head-wrap">
        <div className="head-image">
          <GatsbyImage
            image={iconDisplay}
            alt={iconAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="head-meta">
          <p>{postDate}</p>
          <p>By {author}</p>
        </div>
        <div className="head-title">
          <h1>{title}</h1>
        </div>
      </div>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.header`
  .head-wrap {
    ${medWrapper};
  }

  .head-image {
    width: 100%;
  }

  .head-meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;
    border-bottom: 2rem solid ${colors.colorPrimary};

    p {
      ${B1Blue};
      margin: 0;
      font-weight: bold;
    }
  }

  .head-title {
    width: 100%;
    padding-top: 7.5rem;
    text-align: center;

    h1 {
      ${H1White};
      display: inline-block;
      padding: 0.5rem 2rem;
      background-color: ${colors.colorPrimary};
    }
  }
`

export default PostHeader

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { B1Blue, medWrapper } from "../../../styles/helpers"

const PostNav = ({ prevPost, nextPost }) => {
  return (
    <PostNavStyled>
      <div className="wrapper">
        <nav>
          {nextPost && (
            <Link to={`/news/${nextPost}`}>
              <span>&lt; </span>
              Next Article
            </Link>
          )}
          <Link to="/news">Home</Link>
          {prevPost && (
            <Link to={`/news/${prevPost}`}>
              Previous Article
              <span>&gt; </span>
            </Link>
          )}
        </nav>
      </div>
    </PostNavStyled>
  )
}

const PostNavStyled = styled.div`
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .wrapper {
    ${medWrapper};
    max-width: 95rem !important;
  }

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;

    a {
      ${B1Blue};
      font-weight: bold;
    }
  }
`

export default PostNav

import React from "react"

import PostHeader from "./PostHeader"
import PostWysiwyg from "./PostWysiwyg"
import PostBottomImages from "./PostBottomImages"
import PostNav from "./PostNav"

const PostSingle = ({ post, prevPost, nextPost }) => {
  return (
    <article>
      <PostHeader
        image={post.postTypePost.featuredImage}
        date={post.date}
        author={post.postTypePost.postAuthor}
        title={post.title}
      />
      <PostWysiwyg wysiwyg={post.postTypePost.postBody} />
      {post.postTypePost.bottomImagesRequired ? (
        <PostBottomImages
          imageone={post.postTypePost.bottomImageOne}
          imagetwo={post.postTypePost.bottomImageTwo}
        />
      ) : null}
      <PostNav prevPost={prevPost} nextPost={nextPost} />
    </article>
  )
}

export default PostSingle
